@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Quicksand:wght@500&display=swap");

* {
  font-family: "Quicksand", sans-serif;
}
.comp {
  margin-bottom: 50px;
  display: block;
  z-index: 0;
}

.image {
  max-height: 850px;
  max-width: 850px;
  display: block;
  float: left;
  border-radius: 1.5%;
}

.btn {
  background-color: #caa43cbc; /* Green */
  border: none;
  color: white;
  padding: 20px 1px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
  border-radius: 4px;
  transition: 0.7s;
  float: right;
  margin-left: 2px;
}
.btn:hover {
  background-color: #000000; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.7s;
  float: right;
  margin-left: 2px;
}

.btn2 {
  background-color: #caa43cbc; /* Green */
  border: none;
  color: white;
  padding: 20px 1px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
  border-radius: 4px;
  transition: 0.7s;
  float: left;
}
.btn2:hover {
  background-color: #000000; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.7s;
  float: left;
}

.parent {
  border: 0px solid rgba(0, 0, 0, 0.137);
  margin: 1rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  border-radius: 10px;
}

.parentAdminPage {
  background-color: rgb(245, 244, 244);
  border: 0px solid rgba(0, 0, 0, 0.137);
  margin: 1rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  border-radius: 10px;
  margin-left: 3.5%;
  box-shadow: inset 0 0em 0em rgba(0, 0, 0, 0.1), 0 0 0 0px rgb(255, 255, 255),
    0.1em 0.1em 0.3em rgba(0, 0, 0, 0.23);
}

.imageChild {
  display: inline-block;
  border: 0.5px solid rgba(255, 136, 0, 0.075);
  border-radius: 5px;
  padding: 0.1rem 0.1rem;
  max-height: 900px;
  max-width: 900px;
  vertical-align: top;
}

.buttonChild {
  display: inline-block;
  /* border: 1px solid rgba(255, 0, 0, 0.075); */
  border-radius: 5px;
  padding: 0.1rem 0.1rem;
  max-height: 650px;
  max-width: 600px;
  vertical-align: top;
}

.formChild {
  background-color: rgb(245, 244, 244);
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 0px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 0.1rem 0.1rem;
  height: 650px;
  width: 600px;
  vertical-align: left;
}

.form {
  padding: 1rem;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  font-weight: bold;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
  float: left;
  margin-left: 0.5rem;
}

.control input,
.control textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.25rem;
  width: 80%;
  float: left;
}

.mark {
  margin-top: -7%;
  align-items: center;
  appearance: none;
  background-color: rgb(88, 170, 63);
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #476341 0 -3px 0 inset;
  box-sizing: border-box;
  color: #e0e9df;
  cursor: pointer;
  display: inline-flex;
  height: 44px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.mark:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #476341 0 -3px 0 inset;
  transform: translateY(-2px);
}

.mark:active {
  box-shadow: #476341 0 3px 7px inset;
  transform: translateY(2px);
}

.markDisabled {
  margin-top: -7%;
  align-items: center;
  appearance: none;
  background-color: rgb(143, 185, 130);
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #545f51 0 -3px 0 inset;
  box-sizing: border-box;
  color: #e0e9df;
  display: inline-flex;
  height: 44px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.messageErr {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.messageErrLink {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.delete {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  align-items: center;
  appearance: none;
  background-color: rgb(170, 79, 63);
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #634141 0 -3px 0 inset;
  box-sizing: border-box;
  color: #e0e9df;
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 14px;
}

.delete:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #634141 0 -3px 0 inset;
  transform: translateY(-2px);
}

.delete:active {
  box-shadow: #634141 0 3px 7px inset;
  transform: translateY(2px);
}

.deleteDisabled {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  align-items: center;
  appearance: none;
  background-color: rgb(185, 130, 130);
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #545f51 0 -3px 0 inset;
  box-sizing: border-box;
  color: #e0e9df;
  display: inline-flex;
  height: 44px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.loaderWrapper {
  position: fixed;
  padding: 0;
  margin-top: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(143, 140, 140, 0.753);
}

.loadingDiv {
  padding-top: 13%;
  display: flex;
  justify-content: center;
}

.loadingDivText {
  margin-top: 0.5%;
  margin-bottom: 3%;
  display: flex;
  color: #000000;
  font-size: 16px;
  justify-content: center;
}
