/* .scrollable {
  overflow: auto;
  max-height: 1000px;
  box-sizing: border-box;
}

.scrollable::-webkit-scrollbar{
 display:none; 
} */

.loadmore {
  padding: 5px;
  height: 40px;
  width: 155px;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 2%;
  cursor: pointer;
  outline: 0;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #ff6600;
  border-color: #ff6600;
}

.loadmore:hover {
  color: #fff;
  background-color: #ff6600;
  border-color: #ff6600;
}

.disableLoadmore {
  padding: 5px;
  height: 40px;
  width: 155px;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 2%;
  outline: 0;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fdd1b4;
  border-color: #fdd1b4;
}

.loadingDiv {
  padding-top: 10%;
  display: flex;
  justify-content: center;
}

.loadingDivText {
  margin-top: 0.5%;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
}

.messageErr {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.messageErrAdd {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translateX(-50%);
}


.messageErr2 {
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translateX(-50%);
}

.messageErrLink {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.wrapper{
  padding-left: min(10vw, 10%);
}


.divWrapAbout{
  border: 10px;
  border-color:#810000;
}

.titleAbout {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.titleAboutStatus {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
}


.contentAboutH {
  position: absolute;
  margin-top: 15%;
  left: 50%;
  transform: translateX(-50%);
  direction: rtl;
  text-align: justify;
}

.contentAboutE {
  position: absolute;
  margin-top: 25%;
  left: 50%;
  transform: translateX(-50%);
  text-align: justify;
}