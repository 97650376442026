/* .nav {
    height: 100px;
  }
.img {
    height: 70px;
  } */

.sidebar {
  background-color: var(--black);
  width: 3.5rem;
  height: 80vh;
  margin-top: 1rem;
  border-radius: 0 30px 30px 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.reset{
  margin: 0rem auto;
}

:root {
  /* Colors */
  --black: #09090c;
  --grey: #a4b2bc;
  --white: #fff;
  --background: rgb(210, 208, 207);  /*rgba(104, 78, 36, 0.1);*/
}

/* html {
  overflow: hidden;
} */

