/* .wrapper{
  background-color: black;
  height: 100vh;
  margin: 0rem 0rem;
} */

.auth {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  max-width: 28rem;
  border-radius: 15px;
  background-color: rgba(184, 74, 0, 0.88);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  padding-top: 2rem;
  text-align: center;
  color: white;
}

.auth h1 {
  text-align: center;
  color: white;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: #fcf0e1;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authButton {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #c99550;
  border: 1px solid #c99550;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.authButton:hover {
  background-color: #be7d28;
  border-color: #be7d28;
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #e4c9b0;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #a3927c;
}

.warning {
  color: rgb(151, 1, 1);
  font-weight: bold;
}
